import React, { useLayoutEffect, useEffect, useState } from "react"

import NavBar from "../components/NavBar"
import ContactForm from "../components/ContactForm"
import EmailSuccess from "../components/EmailSuccess"
import Spinner from "../components/Spinner"
import ServerError from "../components/ServerError"
import Footer from "../components/Footer"

import Amplify from "@aws-amplify/core"
import awsconfig from "../aws-exports"

Amplify.configure(awsconfig)

export const Head = () => (
  <>
    <title>Información, contacto paneles solares - Chile</title>
    <meta
      name="description"
      content="Envía tu pregunta y obtén más información a través de nuestro formulario de contacto"
    />
    <meta
      name="keywords"
      content="GlobalAxxis, Contacto, Email, Teléfono, Dirección, Ventas, Prensa, Ubicación, Oficina"
    />
  </>
)

const ContactPage = props => {
  const [view, setView] = useState("contact")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")

  const form = React.useRef()
  const [formHeight, setFormHeight] = useState("inherit")

  useEffect(() => {
    setFormHeight(form.current.offsetHeight)
  })

  const emailCallback = (_view, _name, _email) => {
    setView(_view)
    setName(_name)
    setEmail(_email)
  }

  var template
  switch (view) {
    case "spinner":
      template = <Spinner />
      break
    case "success":
      template = (
        <EmailSuccess
          name={name}
          email={email}
          text={
            "¡Tu consulta ha sido enviada exitosamente!\nProntamente nos pondremos en contacto a tu correo"
          }
        />
      )

      // Google Ads conversion tracking
      //typeof window !== "undefined" &&
      //  typeof window.gtag !== "undefined" &&
      //  window.gtag("event", "conversion", {
      //    send_to: "AW-832693741/zMO0COT14dkDEO3Lh40D",
      //  })

      // Delta Digital Google Ads Campaign
      //typeof window !== "undefined" &&
      //  typeof window.gtag !== "undefined" &&
      //  window.gtag("event", "conversion", {
      //    send_to: "AW-10935866203/SrtoCOXj-N4DENum0N4o",
      //  })
      break
    case "error":
      template = <ServerError />
      break
    default:
      template = <ContactForm emailCallback={emailCallback} />
      break
  }

  return (
    <>
      <NavBar {...props} />
      <section
        id="contact"
        className="body"
        ref={form}
        style={{ height: formHeight }}
      >
        <div className="text-center">
          <h1 style={{ color: "#6a6a6a" }}>Contacto</h1>
          <h3 style={{ color: "#6a6a6a" }}>
            Si tienes preguntas, no dudes en contactarnos
          </h3>
        </div>
        {template}
      </section>
      <Footer />
    </>
  )
}

export default ContactPage
